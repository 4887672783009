<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>话题管理</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/socialTheme' }">话题列表</el-breadcrumb-item>
                <el-breadcrumb-item>编辑</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info margin-l-sm">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px" v-loading="boxLoading">
                 <el-form-item label="名称：" label-position="left" prop="name">
                    <el-input v-model="form.name" maxlength=50 show-word-limit=true style="width: 400px;" />
                </el-form-item>

                <el-form-item label="话题：" label-position="left" prop="theme">
                    <el-input v-model="form.theme" type="textarea" maxlength=255 show-word-limit=true rows=3 style="width: 400px;" />
                </el-form-item>

                <el-form-item label="图标：" label-position="left" prop="icon">
                    <select-images @onSelect="onSelect" @onDelete="onDelete" :image="image"></select-images>
                </el-form-item>

                <el-form-item label="排序：" label-position="left" prop="sort">
                    <el-input-number size="mini" v-model="form.sort" label="排序"></el-input-number>
                </el-form-item>

                <el-form-item label="状态：" label-position="left" prop="status">
                    <el-select v-model="form.status" placeholder="请选择状态">
                        <el-option
                        v-for="item in statusOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="推荐：" label-position="left" prop="recommend">
                    <el-select v-model="form.recommend" placeholder="请选择推荐">
                        <el-option
                        v-for="item in recommendOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import SelectImages from "../../../components/selectImages/index";
export default {
    name: "template-add",
    components: { SelectImages },
    data() {
        return {
            boxLoading: false,
            id: null,
            image: '',
            statusOptions: [
                {
                    label: "上架",
                    value: 1
                },
                {
                    label: "下架",
                    value: 0
                }
            ],
            recommendOptions: [
                {
                    label: "是",
                    value: 1
                },
                {
                    label: "否",
                    value: 0
                }
            ],
            form: {
                name: '',
                theme: '',
                icon: '',
                sort: 0,
                status: 1,
                recommend: 0
            },
            rules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                theme: [
                    { required: true, message: '请输入话题', trigger: 'blur' },
                ],
                icon:[
                    { required: true, message: '请选择图标', trigger: 'change' }
                ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' }
                ],
                status: [
                    { required: true, message: '请选择状态', trigger: 'change' }
                ],
                recommend: [
                    { required: true, message: '请选择是否推荐', trigger: 'change' }
                ]
            },
            loading: false
        }
    },
    methods: {
        ...mapActions('socialTheme', ['add', 'get']),
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.socialThemeAdd()
                }
            });
        },
        async socialThemeAdd() {
            this.loading = true
            const { data, res_info } = await this.add(this.form)
            this.loading = false
            if (res_info != 'ok') return
            this.$message.success('编辑成功！')
            this.$router.push('/socialTheme')
        },
        onSelect(image) {
            this.image = imageUrl + image.path
            this.form.pic = image.path
        },
        onDelete() {
            this.image = ''
        },
        async init() {
            this.boxLoading = true
            const { data } = await this.get(this.id)
            this.form = JSON.parse(JSON.stringify(data))
            this.image = imageUrl + data.icon
            this.boxLoading = false
        }
    },
    mounted() {
        this.id = this.$route.params.id
        this.init()
    },
}
</script>

<style scoped>
.margin-l-sm {
    margin-left: 1.25rem;
}
</style>
